import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { auth } from './services/Auth';
import { onAuthStateChanged } from 'firebase/auth'

Vue.config.productionTip = false

let setSnackbar = (message) => {
  store.commit("SET_SNACKBAR", { message })
}

Vue.prototype.$setSnackbar = setSnackbar

var app;
var referralCode = ''

onAuthStateChanged(auth, (user) => {
  
  store.commit("SET_CURRENT_USER", user)
    
  router.beforeEach((to,from,next)=>{    
    referralCode = to.query.referralCode

    if(from.name == 'Sign In' && !!user) {
      next()
    }

    if(to.matched.some(record => record.meta.requiresAuth) && !user) {
      !!referralCode? next("/influencer-auth/?referralCode="+referralCode) : next("/influencer-auth")
    }
    if(to.matched.some(record => record.meta.requiresGuest) && !!user) {
      next("/proposals")
    }
    next()
  })
  if (!app) { initApp() }
})

function initApp() {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')  
}