<template>
  <stepper-content-card
    @skipStep="$emit('skipStep')"
    @changeStep="handleStepChange"
    :dataLoading="dataLoading"
    :step="5"
    :lastStep="true"
    imgUrl="https://images.unsplash.com/photo-1488223060687-648b43f5efc0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1455&q=80"
  >
    <div slot="heading">About</div>
    <div slot="subheading">Let us know you better</div>
    <div slot="body">
      <v-row align="center" no-gutters>
        <v-col cols="12">
          <v-text-field
            class="mt-4"
            outlined
            dense
            v-model="city"
            label="City"
            placeholder="Enter current city"
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="languages"
            :items="['Assamese', 'English', 'Bengali', 'Hindi']"
            outlined
            hide-details
            dense
            chips
            small-chips
            label="Languages"
            placeholder="Which languages do you speak"
            multiple
          ></v-autocomplete>

          <!-- <v-text-field
            class="mt-4"
            outlined
            dense
            v-model="languages"
            label="Languages"
            placeholder="Which languages do you speak"
            hide-details
          /> -->
        </v-col>
        <v-col cols="6">
          <v-select
            class="mt-4"
            outlined
            dense
            v-model="minRatePerPost"
            label="Min. Rate/Post (₹)"
            hide-details
            :items="[
              'less than 500',
              '500 to 1000',
              '1000 to 3000',
              'more than 3000',
            ]"
          >
          </v-select>
        </v-col>
        <v-col cols="6 pl-2">
          <v-checkbox
            v-model="acceptBarter"
            hide-details
            label="Accept Barter"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" class="mt-4">
          <v-textarea
            outlined
            rows="3"
            v-model="bio"
            placeholder="Few words about yourself"
            label="Bio"
          >
          </v-textarea>
        </v-col>
      </v-row>
    </div>
  </stepper-content-card>
</template>

<script>
import StepperContentCard from "./StepperContentCard";

export default {
  props: ["currentPerson", "dataLoading"],
  components: {
    StepperContentCard,
  },
  data() {
    return {
      bio: "",
      languages: [],
      city: "",
      minRatePerPost: "less than 500",
      acceptBarter: false,
    };
  },
  mounted() {
    this.bio = this.currentPerson.bio;
    this.languages = this.currentPerson.languages;
    this.city = this.currentPerson.city;
    this.minRatePerPost = !!this.currentPerson.minRatePerPost
      ? this.currentPerson.minRatePerPost
      : "less than 500";
    this.acceptBarter = !!this.currentPerson.acceptBarter
      ? this.currentPerson.acceptBarter
      : false;
  },
  methods: {
    handleStepChange() {
      if (!this.city.trim().length || !this.languages.length) {
        this.$setSnackbar("Please fill up all fields");
        return;
      }

      this.$emit("changeStep", {
        bio: this.bio,
        languages: this.languages,
        city: this.city,
        minRatePerPost: this.minRatePerPost,
        acceptBarter: this.acceptBarter,
        onboarded: true,
      });
    },
  },
};
</script>