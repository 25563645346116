<template>
  <div>    
    <v-container>
      <v-row style="margin-top:10vh;">
        <v-col cols="12">
          <h1 class="display-2 font-weight-black">
            Love to <br> all Influencers
          </h1>          
        </v-col>      
        <v-col class="mt-5">
          <p style="color:#232323;font-size:24px;">Continue with Google</p>
          <v-btn @click="_signIn" large class="mt-3" rounded>
            <v-avatar size="30" class="mr-3">
              <img              
                src="@/assets/search.png"
                alt="Google Icon"
              >
            </v-avatar>            
            Login
          </v-btn>
        </v-col>
      </v-row>
    </v-container>    
  </div>
</template>

<script>
  import { signIn } from "@/services/Auth.js"
  export default {
    methods: {
      _signIn() {
        signIn().then(user => {
          this.$router.replace("/account")
        })
      }
    }
  }
</script>