<template>
  <div>    
    <v-container>
      <v-row style="margin-top:10vh;">
        <v-col cols="12">
          <h1 class="display-1 font-weight-black text-center">
            Monetize your digital presence.
          </h1>          
        </v-col>      
        <v-col class="mt-5 text-center">
          <p class="overline" >Continue with Google</p>
          <v-btn :loading="isLoading || !!currentUser" @click="_signIn" large class="mt-3" rounded>
            <v-avatar size="30" class="mr-3">
              <img              
                src="@/assets/search.png"
                alt="Google Icon"
              >
            </v-avatar>            
            Login
          </v-btn>
        </v-col>
      </v-row>
    </v-container>    
  </div>
</template>

<script>
  import { signInWithGoogle } from "@/services/Auth.js"
  import { getInfluencer, updatePerson, addPerson } from './../api/Influencer'
  import { mapGetters } from 'vuex'

  export default {
    data: () => ({
      isLoading: false
    }),
    computed: {
      ...mapGetters(['currentUser'])
    },
    methods: {
      _signIn() {
        this.isLoading = true
        signInWithGoogle()
        .then(async ({ user, isFirstLogin }) => {
          this.$store.commit("SET_SNACKBAR", { message: "Sign In Successful" })
          let referralCode = this.$route.query.referralCode
          let personData = {}

          // if(true && !!referralCode) {
          // if(isFirstLogin && !!referralCode) {
          //     personData['referredBy'] = referralCode
          // }

          if(isFirstLogin) {         
            personData['referredBy'] = !!referralCode? referralCode : null
            personData['id'] = user.uid
            await addPerson(personData)
            console.log("person created")
          }
          
          window.location = '/'

          // await updatePerson(user.uid, { referredBy: referralCode })
          // let influencer = await getInfluencer(user.uid)
          // window.location = !!influencer.onboarded? "/" : "/getting-started"
        })
        .catch(e => {
          console.log(e)
          this.$store.commit("SET_SNACKBAR", { message: "Error. Please try again" })
          this.isLoading = false
        })
      }
    }
  }
</script>