<template>
  <v-app>
    <template v-if="pageLoaded">
      <common-actions />
      <div v-if="$route.path == '/influencer-auth'" class="bg-img"></div>

      <template v-if="$route.name !== 'Getting Started'">
        <v-app-bar flat app color="secondary" dark>
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <!-- <div class="d-flex align-center"> -->
          <!-- <v-icon @click="$router.push('/')" left>mdi-heart-multiple</v-icon>             -->
          <v-toolbar-title class="subtitle-1">
            {{ $route.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            small
            text
            v-if="webShareApiSupported"
            @click="shareViaWebShare"
            class="hidden-lg-and-up"
          >
            <v-icon left>mdi-share</v-icon> SHARE
          </v-btn>
          <v-toolbar-items class="hidden-sm-and-down">
            <v-btn text v-if="!!currentUser" to="/proposals">CAMPAIGNS</v-btn>
            <v-btn text v-if="!!currentUser" to="/account">PROFILE</v-btn>
            <!-- <v-btn text v-if="!!currentUser" to="/tasks">TASKS</v-btn> -->
            <v-btn text to="/submit-feedback">FEEDBACK</v-btn>
          </v-toolbar-items>

          <!-- </div> -->
          <!-- 
      <v-spacer></v-spacer>

      <v-badge
        bordered                
        overlap
        color="secondary"
        content="1"        
        v-if="currentUser"
      >
        <v-btn
          to="/proposals"
          small
          icon
          depressed
        >
          <v-icon>
            mdi-bell
          </v-icon>
        </v-btn>
      </v-badge> -->

          <!-- <v-btn            
        dark
        outlined
      >
      <v-icon left>mdi-headset</v-icon>
      HELP 
      </v-btn> -->
        </v-app-bar>

        <v-navigation-drawer temporary v-model="drawer" app>
          <div
            style="
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <div>
              <v-row>
                <v-col class="text-center my-3">
                  <!-- <v-img  src="../assets/logo1.png" class="mx-auto mb-4" style="width:70%;"></v-img> -->

                  <v-card
                    :ripple="false"
                    flat
                    color="transparent"
                  >
                    <v-img
                      style="margin: 0 auto"
                      width="80px"
                      contain
                      src="./assets/connect_logo.png"
                    />
                  </v-card>
                  <!-- <span>
                    Veniu <span class="font-weight-medium">Connect</span> 
                  </span> -->
                </v-col>
              </v-row>
              <v-list class="nav-items">
                <v-list-item-group color="primary">
                  <v-list-item v-if="currentUser" to="/proposals">
                    <v-list-item-title>CAMPAIGNS</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :input-value="true"
                    v-if="currentUser"
                    to="/account"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item v-if="currentUser" to="/tasks">
                    <v-list-item-title>Tasks</v-list-item-title>
                  </v-list-item> -->
                  <!-- <v-list-item to="/service">
              <v-list-item-title>About</v-list-item-title>
          </v-list-item>
          <v-list-item to="/contact">
              <v-list-item-title>Contact</v-list-item-title>
          </v-list-item> -->
                </v-list-item-group>
              </v-list>
            </div>
            <div style="margin-bottom: 7vh">
              <v-divider />
              <v-container grid-list-xs fluid>
                <v-row>
                  <v-col cols="12">
                    <div class="mb-3">
                      <v-icon class="mr-3" color="dark">mdi-phone</v-icon>
                      +91 8638427817
                    </div>
                    <div class="mb-5 mail text-truncate">
                      <v-icon class="mr-3" color="dark">mdi-email</v-icon>
                      <a href="mailto:connect.veniu@gmail.com"
                        >connect.veniu@gmail.com</a
                      >
                    </div>
                    <v-row no-gutters>
                      <v-col>
                        <v-btn
                          class="mr-1"
                          block
                          to="/submit-feedback"
                          depressed
                          color="primary"
                          >FEEDBACK</v-btn
                        >
                      </v-col>
                      <v-col>
                        <v-btn
                          href="https://wa.me/+918638427817"
                          target="_blank"
                          class="ml-1"
                          block
                          depressed
                          outlined
                        >
                          <v-icon left>mdi-whatsapp</v-icon> HELP
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12 text-center">
                    <h5 class="overline">STAY UPDATED WITH US</h5>
                  </v-col>
                  <v-col cols="12 d-flex justify-space-around">
                    <a href="https://www.twitter.com/reachgap" target="_blank">
                      <v-icon large>mdi-twitter</v-icon>
                    </a>
                    <a
                      href="https://www.facebook.com/Reachgap-102442351810724"
                      target="_blank"
                    >
                      <v-icon large>mdi-facebook</v-icon>
                    </a>
                    <a
                      href="https://www.instagram.com/reachgap"
                      target="_blank"
                    >
                      <v-icon large>mdi-instagram</v-icon>
                    </a>
                  </v-col>
                  <v-col v-if="!!currentUser" cols="12" class="text-center">
                    <v-btn color="accent" small @click="_signOut" outlined block
                      >Log Out</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </v-navigation-drawer>
      </template>

      <v-main>
        <router-view @refer="handleRefer($event)" :currentPerson="currentPerson" ></router-view>
      </v-main>
      <v-bottom-navigation
        class="d-flex d-sm-none"
        app
        color="primary"
        grow
        v-if="!!currentUser && $route.name !== 'Getting Started'"
      >
        <!-- <v-btn to="/tasks">
          <span>TASKS</span>
          <v-icon>mdi-forum</v-icon>
        </v-btn> -->
        <v-btn to="/proposals">
          <span>CAMPAIGNS</span>

          <v-icon>mdi-heart</v-icon>
        </v-btn>
        <v-btn to="/account">
          <span>PROFILE</span>

          <v-icon>mdi-account</v-icon>
        </v-btn>        
      </v-bottom-navigation>
    </template>
    <v-progress-linear
      v-else
      :active="true"
      :indeterminate="true"
      absolute
      top
      color="primary"
    ></v-progress-linear>
  </v-app>
</template>

<script>
import Home from "./views/Home";
import CommonActions from "./components/CommonActions.vue";
import { appSignOut } from "@/services/Auth";
import { mapGetters } from "vuex";
import { getInfluencer } from "./api/Influencer";

export default {
  name: "App",

  components: {
    Home,
    CommonActions,
  },
  data: () => ({
    drawer: false,
    selectedItem: null,
    pageLoaded: false,
    currentPerson: {}
  }),
  computed: {
    webShareApiSupported() {
      return navigator.share;
    },
    ...mapGetters(["currentUser"]),
  },
  methods: {
    shareViaWebShare(code) {
      let url = "https://connect.veniu.in"
      
      if(typeof code == 'string') {
        url = url + '?referralCode='+code
      }

      navigator.share({
        title: "Invitation to join Veniu Connect",
        text: "Start monetizing your digital presence with us",
        url: url
        // url: "https://veniu.connect.in/"+!!typeof code == 'string'? '?referralCode='+code : ''
      });
    },
    handleRefer(code) {
      // console.log(code)
      // console.log("https://veniu.connect.in/"+!!code? '?referralCode='+code : '')
      this.shareViaWebShare(code)
    },
    _signOut() {
      appSignOut()
        .then(() => {
          // this.$router.replace("/influencer-auth");
          window.location = "/influencer-auth";
        })
        .catch((e) => console.log(e));
    },
  },
  async created() {
    console.log("app created ...")

    if (!!this.currentUser & this.$route.path !== '/influencer-auth') {
      const influencer = await getInfluencer(this.currentUser.uid);
      this.currentPerson = influencer

      if (!influencer.onboarded && this.$route.name !== "Getting Started") {
        this.$router.replace("/getting-started?step=1").catch(e => {})
      }

      if(influencer.onboarded && this.$route.name == "Getting Started") {
        this.$router.replace("/");
      }

    }
    this.pageLoaded = true;
  },
  mounted() {
    this.selectedItem = 0;
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>


<style scoped>
a {
  text-decoration: none;
}

.bg-img {
  position: absolute;
  height: 100vh;
  z-index: -1;
  background: url("./assets/register_bg_mobile.png");
  background-size: contain;
  background-position: bottom;
  width: 100%;
  background-repeat: no-repeat;
}
</style>