<template>
  <div>
    <v-form v-model="isValid" ref="form">
      <v-container>
        <v-row justify="center">
          <v-col class="d-flex align-end justify-center" cols="12">
            <v-avatar class="grey lighten-2" :size="120">
              <v-row
                v-if="profileImgUploading"
                align-content="center"
                class="fill-height"
              >
                <v-col>
                  <v-progress-circular
                    :size="40"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
              <v-img v-else :src="influencer.profileImg" class="grey lighten-2">
                <v-icon large v-if="!influencer.profileImg">mdi-account</v-icon>
              </v-img>
            </v-avatar>
            <div style="width: 30px">
              <v-file-input
                ref="profileImgUploadBtn"
                label="File input"
                accept="image/*"
                outlined
                dense
                hide-input
                solo
                prepend-icon="mdi-camera"
                @change="uploadImg($event)"
              >
                <!-- @update:error="onProfileImgErr($event)" -->
              </v-file-input>
            </div>
          </v-col>
          <v-col lg="6" cols="12">
            <v-expansion-panels multiple v-model="activePanel">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Basic info
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="isActivePanel == 0" eager>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        prepend-inner-icon="mdi-account"
                        outlined
                        hide-details
                        name="name"
                        label="Name"
                        id="id"
                        dense
                        v-model="influencer.name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        prepend-inner-icon="mdi-email"
                        outlined
                        dense
                        :hide-details="!isSubmitted"
                        label="Email"
                        v-model="influencer.email"
                        :rules="emailRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        prepend-inner-icon="mdi-cellphone"
                        outlined
                        dense
                        :hide-details="!isSubmitted"
                        label="Mobile (Optional)"
                        prefix="+91"
                        v-model="influencer.mobile"
                        :rules="numberRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-map-marker"
                        label="City"
                        id="id"
                        v-model="influencer.city"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="influencer.languages"
                        :items="['Assamese', 'English', 'Bengali', 'Hindi']"
                        outlined
                        hide-details
                        dense
                        chips
                        small-chips
                        label="Languages"
                        placeholder="Which languages do you speak"
                        multiple
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        outlined
                        prepend-inner-icon="mdi-comment"
                        label="Bio"
                        rows="2"
                        v-model="influencer.bio"
                      />
                      <!-- counter -->
                      <!-- maxlength="100" -->
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Social Presence
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="isActivePanel == 1" eager>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-select
                        class="mt-4"
                        outlined
                        dense
                        label="Primary Platform"
                        hide-details
                        v-model="influencer.platform"
                        placeholder=""
                        :items="['Instagram', 'Youtube', 'Facebook']"
                      >
                      </v-select>
                    </v-col>
                    <v-col class="text-center mt-4" cols="12">
                      <h5 class="overline">PROFILE LINKS</h5>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        class="mt-4"
                        outlined
                        dense
                        v-model="influencer.instagram"
                        label="Instagram Profile Link"
                        hide-details
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        class="mt-4"
                        outlined
                        dense
                        v-model="influencer.facebook"
                        label="Facebook Profile Link"
                        hide-details
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        class="mt-4"
                        outlined
                        dense
                        v-model="influencer.youtube"
                        label="Youtube Profile Link"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Speciality
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="isActivePanel == 2" eager>
                  <v-row no-gutters>
                    <template v-if="!!selectedTopicList.length">
                      <v-col cols="12" class="pt-0">
                        <v-chip
                          class="mr-2 mt-2"
                          color="accent"
                          close
                          @click:close="selectedTopicList.splice(index, 1)"
                          v-for="(topic, index) in selectedTopicList"
                          :key="`topic-${index}.`"
                        >
                          {{ topic }}
                        </v-chip>
                      </v-col>
                    </template>
                    <v-col class="mt-4" cols="12">
                      <v-select
                        ref="topicSelectDropdown"
                        placeholder="Tap here to select"
                        outlined
                        @change="addNewTopic"
                        label="Add Industry"
                        :items="filteredTopicList"
                        hint="Max 5 selections"
                        persistent-hint
                        :disabled="selectedTopicList.length >= 5"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Monetization
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="isActivePanel == 3" eager>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-select
                        class="mt-4"
                        outlined
                        dense
                        v-model="influencer.minRatePerPost"
                        label="Min. Rate/Post (₹)"
                        hide-details
                        :items="[
                          'less than 500',
                          '500 to 1000',
                          '1000 to 3000',
                          'more than 3000',
                        ]"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="influencer.acceptBarter"
                        hide-details
                        label="Accept Barter"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <!-- <v-select
              ref="platformSelectDropdown"
              v-model="influencer.platform"
              @change="clearPlatformLink"
              outlined
              hide-details
              label="Platform"
              :items="['Instagram']"
            /> -->
            <!-- <v-select
            ref="platformSelectDropdown"
            v-model="influencer.platform.name"
            @change="clearPlatformLink"
            outlined
            persistent-hint
            hint="Set your main platform (Only one allowed)"
            label="Platform"
            :items="['Instagram', 'Youtube']"
          /> -->
          </v-col>
          <!-- <v-col lg="6" cols="12">
            <v-text-field
              ref="platformLinkField"
              outlined
              :hide-details="!isSubmitted"
              :rules="accountLinkRules"
              label="Profile Url"
              :placeholder="platformPlaceholder"
              v-model="influencer.instagram"
            ></v-text-field>
          </v-col> -->
          <!-- <v-col md="6" cols="12">
          <v-text-field
            outlined hide-details
            label="Facebook Link"
            prepend-inner-icon="mdi-facebook"
            v-model="influencer.facebook"            
          ></v-text-field>
        </v-col>  
        <v-col md="6" cols="12">
          <v-text-field
            outlined hide-details
            label="Facebook Link"
            prepend-inner-icon="mdi-facebook"
            v-model="influencer.facebook"            
          ></v-text-field>
        </v-col>  
        <v-col md="6" cols="12">
          <v-text-field
            outlined hide-details
            label="Youtube Link"
            v-model="influencer.youtube"
            prepend-inner-icon="mdi-youtube"
          ></v-text-field>
        </v-col>                                        -->

          <!--        <v-col class="mb-4" lg="6" cols="12">
          <h5 class="overline">Languages</h5>
          <v-row no-gutters>
            <v-col cols="6">
              <v-checkbox v-model="influencer.languages" hide-details label="English" value="english"></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox v-model="influencer.languages" hide-details label="Assamese" value="assamese"></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox v-model="influencer.languages" hide-details label="Hindi" value="hindi"></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox v-model="influencer.languages" hide-details label="Bengali" value="bengali"></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
-->
        </v-row>

        <v-row>
          <v-col cols="12" md="4" offset-md="4">
            <v-btn @click="addInfluencer" depressed block color="primary"
              >SAVE</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <div class="text-center">
      <v-dialog v-model="dialog" :scrollable="false" width="500">
        <v-card>
          <v-img
            max-height="300px"
            src="@/assets/final.png"
            aspect-ratio="1.2"
            contain
          ></v-img>
          <v-card-title class="headline justify-center">
            Awesome!!
          </v-card-title>
          <v-card-text class="text-center">
            <h4 class="subtitle-2">
              What would be your preferred mode of contact?
            </h4>
            <v-row class="mb-3 mt-5 pt-4" justify="space-around">
              <div>
                <v-btn
                  @click="setContact('fb')"
                  depressed
                  color="primary"
                  fab
                  small
                >
                  <v-icon>mdi-message</v-icon>
                </v-btn>
                <div class="overline">PHONE</div>
              </div>
              <div>
                <v-btn
                  @click="setContact('instagram')"
                  depressed
                  color="primary"
                  fab
                  small
                >
                  <v-icon>mdi-instagram</v-icon>
                </v-btn>
                <div class="overline">INSTAGRAM</div>
              </div>
              <div>
                <v-btn
                  @click="setContact('email')"
                  depressed
                  color="primary"
                  fab
                  small
                >
                  <v-icon>mdi-email</v-icon>
                </v-btn>
                <div class="overline">EMAIL</div>
              </div>
            </v-row>

            <!-- <div class="font-italic">or</div>

            <v-text-field
              class="mt-4"
              outlined
              hide-details
              label="Moble Number"
              append-icon="mdi-send"
              prefix="+91"
              length="10"
              v-model="mobile"
              @click:append="setContact('mobile')"
            >              
            </v-text-field> -->
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-snackbar v-model="snackbar.state" :timeout="1500">{{
      snackbar.message
    }}</v-snackbar>
  </div>
</template>

<script>
import { getInfluencer, updatePerson } from "./../api/Influencer";
import { mapGetters } from "vuex";
import axios from "axios";
const object = require("lodash/object");
const _difference = require("lodash/difference");

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = Object.assign({}, from);
    });
  },
  data: () => ({
    isValid: true,
    profileImgRules: [
      (value) =>
        !value ||
        value.size < 1000000 ||
        "Avatar size should be less than 10 MB!",
    ],
    prevRoute: null,
    activePanel: [0],
    isSubmitted: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    accountLinkRules: [(v) => !!v || "Account link is required"],
    profileImgUploading: false,

    influencer: {
      name: "",
      city: "",
      instagram: "",
      facebook: "",
      youtube: "",
      profileImg: "",
      bio: "",
      topics: [],
      contact: null,
      email: "",
      mobile: "",
      languages: [],
      platform: "",
      isNewApplication: false,
      isActivePanel: null,
    },
    snackbar: {
      state: false,
      message: "",
    },
    dialog: false,
    topicList: [
      "Beauty",
      "Fashion",
      "Food",
      "Lifestyle",
      "Comedy",
      "Technology",
      "Art",
      "Drama",
      "Writing",
      "News",
      "Photography",
      "Sports",
      "Health & Wellness",
      "Travel",
      "Education",
      "Music",
      "Dance",
      "Fitness",
      "Fun & Memes",
      "Content Creation",
      "Others",
    ],
    selectedTopicList: [],
    mobile: null,
    isFromLogin: false,
    influencerLocal: {},
    expansionPanelUpdated: false,
    isActivePanel: null,
  }),
  mounted() {
    this.$store.commit("SET_LOADING_STATE", true);

    getInfluencer(this.currentUser.uid).then((data) => {
      if (!!data) {
        this.influencer = object.merge(this.influencer, data);
        this.selectedTopicList = [...this.influencer.topics];
        this.influencerLocal = Object.assign({}, this.influencer);
      } else {
        this.isNewApplication = true;
        this.influencer.name = this.currentUser.displayName;
        this.influencer.email = this.currentUser.email;
      }
      this.$store.commit("SET_LOADING_STATE", false);

      setTimeout(() => {
        this.isActivePanel = this.activePanel[0];
      }, 700);
    });
  },
  computed: {
    numberRules() {
      return !!this.influencer.mobile
        ? [
            (v) => Number.isInteger(Number(v)) || "Number must be valid",
            (v) => !!v || "Phone Number is required",
            (v) => (v && v.length == 10) || "Number should be 10 digit",
          ]
        : [];
    },
    filteredTopicList() {
      return this.topicList.filter(
        (topic) => !this.selectedTopicList.includes(topic)
      );
    },
    ...mapGetters(["currentUser"]),
    platformPlaceholder() {
      return `Enter your ${this.influencer.platform.name} username here`;
    },
  },
  methods: {
    // onProfileImgErr(val) {
    //   alert("Only images with size < 10 MB are allowed");
    // },
    async uploadImg(img) {
      let imgSize = img.size;
      if (imgSize > 10000000) {
        alert("Only images with size < 10 MB are allowed");
        return;
      } else {
        this.profileImgUploading = true;
        try {
          var imgUrl = await this.uploadFile(img);
          await updatePerson(this.currentUser.uid, { profileImg: imgUrl });
          this.influencer.profileImg = imgUrl;
          this.$store.commit("SET_SNACKBAR", {
            message: "Uploaded Successfully",
          });
        } catch (error) {
          console.log(error);
        }
        this.profileImgUploading = false;
      }
      // this.imageSelected = true
      // if (!img) { return }
      // this.previewImages[index] = URL.createObjectURL(img)
      // this.images[index] = img
      // this.$forceUpdate()
    },
    uploadFile(image) {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("folder", "Reachgap/" + this.influencer.id);
      formData.append("size", 300);
      return (
        axios
          .post("https://thevegaforce.df.r.appspot.com/upload", formData)
          // .post("http://localhost:9000/upload", formData)
          .then((res) => res.data.url)
          .catch((err) => console.log(err))
      );
    },
    addNewTopic(data) {
      this.selectedTopicList.push(data);
      this.$refs.topicSelectDropdown.reset();
      this.$refs.topicSelectDropdown.isFocused = false;
    },
    addInfluencer() {
      if (this.$refs.form.validate()) {
        this.isSubmitted = false;
        this.$store.commit("SET_LOADING_STATE", true);
        if (this.isNewApplication) {
          this.influencer["createdAt"] = Date.now();
        }
        this.influencer["updatedAt"] = Date.now();

        // this.influencer['platform'].url = 'https://instagram.com/'+this.influencer['platform'].url

        updatePerson(this.currentUser.uid, {
          ...this.influencer,
          topics: this.selectedTopicList,
        })
          .then(() => {
            this.$store.commit("SET_LOADING_STATE", false);
            if (this.isNewApplication) {
              this.dialog = true;
            } else {
              this.$nextTick(() => {
                this.snackbar.message = "Saved successfully";
                this.snackbar.state = true;
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.isSubmitted = true;
      }
    },
    clearPlatformLink() {
      this.$refs.platformSelectDropdown.blur();
      this.$refs.platformLinkField.reset();
      this.$refs.platformLinkField.focus();
    },
    setContact(method) {
      updatePerson(this.currentUser.uid, {
        contact: method,
      }).then(() => {
        this.dialog = false;
        this.$store.commit("SET_SNACKBAR", { message: "Saved successfully" });
        this.$router.push("/proposals");
      });
    },
  },
  watch: {
    activePanel: {
      handler(newVal, oldVal) {
        if (oldVal.length == 0 || newVal.length < oldVal.length) {
          if (oldVal.length == 0) {
            setTimeout(() => {
              this.isActivePanel = this.activePanel[0];
            }, 300);
          }
          return;
        }

        let diff = _difference(newVal, oldVal);

        setTimeout(() => {
          this.isActivePanel = diff[0];
        }, 300);

        setTimeout(() => {
          this.activePanel = diff;
        }, 800);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
