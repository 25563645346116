import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import InfluencerAuth from './../views/InfluencerAuth.vue'
import Account from './../views/Account.vue'
import Proposals from './../views/Proposals.vue'
import Tasks from './../views/Tasks.vue'
import SubmitFeedback from './../views/Feedback.vue'
import GettingStarted from './../views/GettingStarted.vue'
import Onboarded from './../views/Onboarded.vue'
import Promo from './../views/Promo.vue'
import Test from './../views/Test.vue'
import NewInsightsEntry from './../views/NewInsightsEntry.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/proposals',    
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/account',
    name: 'Profile',
    component: Account,
    meta: {
      requiresAuth: true
    }    
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
    meta: {
      requiresAuth: true
    }    
  },
  {
    path: '/proposals',
    name: 'My Campaigns',
    component: Proposals,
    meta: {
      requiresAuth: true
    }    
  },
  {
    path: '/promo/:id',
    name: 'Campaign Details',
    component: Promo,
    meta: {
      requiresAuth: true
    }    
  },
  {
    path: '/promo/:id/new-insights-entry',
    name: 'New Insights Entry',
    component: NewInsightsEntry,
    meta: {
      requiresAuth: true
    }    
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks,
    meta: {
      requiresAuth: true
    }    
  },
  {
    path: '/getting-started',
    name: 'Getting Started',
    component: GettingStarted,
    meta: {
      requiresAuth: true
    }   
  },
  {
    path: '/onboarded',
    name: 'Onboarded',
    component: Onboarded,
    meta: {
      requiresAuth: true
    }   
  },
  {
    path: '/influencer-auth',
    name: "Sign In",
    component: InfluencerAuth,
    meta: {
      requiresGuest: true
    }    
  },
  {
    path: '/about',
    name: 'About',    
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/submit-feedback',
    name: 'Feedback',
    component: SubmitFeedback
  }  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
