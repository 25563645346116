<template>
  <div class="white--text" v-if="!!pageLoaded">
    <!-- <v-container class="pa-0">
            <v-row row wrap>
                <v-col cols="12" md="4" offset-md="4"> -->
    <v-container fluid :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
      <v-row no-gutters>
        <v-col md="4" offset-md="4">
          <v-stepper v-if="el < 6" v-model="el">
                <v-stepper-items>
                  <v-stepper-content
                    class="pa-0"
                    v-for="(stepperItem, index) in stepComponents"
                    :key="`stepper-${index}`"
                    :step="`${index + 1}`"
                  >
                    <component
                      @skipStep="handleStepSkip"
                      @changeStep="handleStepChange($event)"
                      :lastStep="index == 4"
                      :dataLoading="dataLoading"
                      :currentPerson="currentPerson"
                      :is="stepperItem"
                    />
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
              <v-card :loading="true" v-else>
                <h2 class="display-2 text-center" style="margin-top:80px;">Finishing Up ...</h2>
                <v-img height="250px" contain class="mt-4" src="@/assets/onboarded.png" />
              </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- </v-col>
            </v-row>
        </v-container> -->
  </div>
</template>

<script>
import StepOne from "@/components/StepOne.vue";
import StepTwo from "@/components/StepTwo.vue";
import StepThree from "@/components/StepThree.vue";
import StepFour from "@/components/StepFour.vue";
import StepFive from "@/components/StepFive.vue";
import { mapGetters } from "vuex";
// import { getPerson, addPerson, updatePerson } from './../api/influencer'
import { addPerson, updatePerson, getInfluencerSync } from "./../api/Influencer";

// const stepComponents = [StepOne, StepTwo, StepThree, StepFour, StepFive]
const stepComponents = [StepOne, StepTwo, StepThree, StepFour, StepFive];

export default {
//  beforeRouteEnter(to, from, next) {
//    from.path == "/account" ? next("/") : next();
//  },
  data() {
    return {
      el: 1,
      currentPerson: {},
      userCreated: false,
      dataLoading: false,
      pageLoaded: false
    };
  },
  computed: {
    stepComponents() {
      return stepComponents;
    },
    currentRoute() {
      return this.$route.query;
    },
    // ...mapGetters(["currentPerson", "loading"])
    ...mapGetters(["loading", "currentUser"]),
  },
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
  },
  // beforeCreate() {
  //     triggerAction("fetchCurrentPerson")
  //     .then(() => {
  //         // if (this.currentPerson.isArtist !== null) { this.$router.replace("/") }
  //     })
  //     .catch(() => {})
  // },
  async created() {


    // let personData = await getInfluencer(this.currentUser.uid);
    getInfluencerSync(this.currentUser.uid, this.onPersonDataFetch)
  },
  methods: {
    onPersonDataFetch(personData) {
      this.currentPerson = personData;
      if (!!personData.id) {
        this.userCreated = true;
        console.log("user exists");
      }
      this.pageLoaded = true
    },
    async handleStepChange(data) {
      // console.log(data);
      // this.nextStep();

      // UPDATE PERSON DATA

      this.dataLoading = true

      try {
        if ((this.el == 1) & !this.userCreated)
          await addPerson({ ...data, id: this.currentUser.uid });
        else await updatePerson(this.currentUser.uid, data);
        this.nextStep();
      } catch (e) {
        console.log(e);
      }

      this.dataLoading = false
    },
    handleStepSkip() {
      this.nextStep(this);
    },
    nextStep() {
      console.log("running next step");
      this.el += 1;
      //this.nextRoute();
      // return;

      setTimeout(() => {
        window.scrollTo(0,0)
      }, 700);      

      if (this.el == 6) {
        setTimeout(() => {
          this.$store.commit("SET_SNACKBAR", {
            message: "Profile set up successfully",
          });
          this.$router.replace("/account");
        }, 3500)
      } else {
        this.nextRoute();
      }
    },
    nextRoute() {
      this.$router.push({ path: "getting-started", query: { step: this.el } }).catch(e => {})
    },
  },
  watch: {
    currentRoute: {
      handler(query) {
        const step = parseInt(query.step);
        if (step < this.el) {
          this.el = step;
        }
      },
      deep: true,
    },
  },
};
</script>