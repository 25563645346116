<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <h1 class="title font-weight-bold mb-2">The Assamese Project</h1>
                    <p class="body-2 mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius inventore accusamus culpa soluta dolores facere tempore, ad quas obcaecati. Quae?
                    </p>
                </v-col>
                <v-col class="text-center" cols="12">
                    <h2 class="overline">INSIGHTS</h2>
                </v-col>
                <v-col cols="12">
                    <v-btn depressed to="/promo/1/new-insights-entry" color="primary" small>
                     ADD NEW ENTRY</v-btn>
                </v-col>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                            <h3 class="subtitle-2">Share/Repost (Facebook)</h3>
                        </v-card-title>
                        <v-card-text>
                            22 Days Active | Posted 10.09.22
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    
}
</script>