<template>
    <div class="reviews review-bg grey lighten-3 pb-5">
        <v-container grid-list-xs>
            <v-row>
                <v-col cols="12" md="6" offset-md="3">
                    <v-card class="pa-5" flat tile>
                        <h1 class="text-center my-4 overline">Please fill up the form</h1>
                        <!-- <div class="text-center mb-5">
                            <hr class="primary" style="margin:5px auto;height:4px;border:none;" width="30">
                        </div> -->
                    <v-form ref="reviewform" lazy-validation>
                        <v-container>
                        <v-row>
                            <v-col
                            cols="12"
                            md="12"
                            class="my-0 pa-0"
                            >
                            <v-text-field
                                v-model="studentreview.name"
                                label="Write your name"
                                :rules="rules"
                                outlined
                            ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="12"
                            class="my-0 pa-0"
                            >
                            <v-text-field
                                v-model="studentreview.email"
                                label="E-mail"
                                :rules="rules"
                                required
                                outlined
                               
                            ></v-text-field>
                            </v-col>
                            <v-col class="my-0 pa-0" cols="12">
                               <v-row>
                                   <v-col cols="12" md="9" class="py-0">
                                       <h3 class="body-1 ml-3 grey--text text--darken-1 font-weight-light" style="line-height:1.4;">Give us a rating</h3>
                                   </v-col>
                                   <v-col cols="12" md="9" class="py-0">
                                    <v-rating                                         
                                        v-model="studentreview.rating"
                                        half-increments
                                        hover
                                    ></v-rating>
                                   </v-col>
                               </v-row>
                            </v-col>
                            <v-col cols="12" class="mt-4 pa-0">
                               <v-textarea
                                name="input-7-1"
                                label="Write Your Review"
                                outlined
                                v-model="studentreview.text"
                                :rules="rules"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        </v-container>
                        <v-btn depressed :loading="submitting" color="primary" @click="submitreview()" large block>Submit</v-btn>
                    </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { addReview } from './../api/Utils'

export default {    
    data(){
        return{
            studentreview:
                {
                    name:"",
                    email:"",
                    rating: 4,
                    text:""
                },
            submitting: false,
            rules: [v => !!v || 'Field cannot be empty ']
        }
    },
    mounted() {
        if(!!this.currentUser) {
            this.studentreview.name = this.currentUser.displayName
            this.studentreview.email = this.currentUser.email
        }
    },
    computed: {
        ...mapGetters(['currentUser'])
    },
    methods:{
        submitreview(){                        
            const res = this.$refs.reviewform.validate()
            if(res) {
                this.submitting = true
                addReview(this.studentreview)
                .then(res => {
                    this.$store.commit("SET_SNACKBAR", { message: "Review Submitted Successfully" })
                    this.$refs.reviewform.reset();                
                })
                .catch(err => {
                    this.$store.commit("SET_SNACKBAR", { message: "Error Submitting Review" })
                })
                .finally(() => {
                    this.submitting = false
                })                
            }
        }            
    }
}
</script>

<style scoped>
  .review-bg {
    /* background: url('https://stmed.net/sites/default/files/blue-white-wallpapers-25171-712462.jpg');
    background-size: cover;     */
  }
</style>