import { doc, collection, getDoc, addDoc, setDoc, updateDoc, getDocs, deleteDoc, onSnapshot, query } from 'firebase/firestore'
import { db } from './../services/database'
import store from './../store'
const randomstring = require('randomstring')

const personsRef = collection(db, "Influencers")

export async function getPersonsList() {
    let items = []
    let snaps = await getDocs(personsRef)
    snaps.forEach(snap => {
        items.push(snap.data())
    })
    return items
}

export async function getInfluencerSync(id, callback) {
    const unsub = onSnapshot(personRef(id), (doc) => {
        doc.exists()? callback(doc.data()) : callback(blankPerson())
    });
}

export async function getInfluencer(id) {
    let ref = personRef(id)
    let snap = await getDoc(ref)
    return snap.exists()? snap.data() : blankPerson()
}

export function addPerson(data) {
    // let docId = doc(personsRef).id
    let docId = data.id
    return setDoc(doc(personsRef, docId), {  ...blankPerson(), ...data })
}

export function updatePerson(id, data) {
    let ref = personRef(id)
    return updateDoc(ref, data)
}

export function removePerson(id) {
    let ref = personRef(id)
    return deleteDoc(ref)
}

function personRef(id) {
    return doc(db, "Influencers", id)
}

function blankPerson() {
    let currentUser = store.getters.currentUser
    let referralCode = randomstring.generate({
        length: 6,
        capitalization: 'uppercase'
    })

    return {
        name: currentUser.displayName, 
        email: currentUser.email, 
        workEmail: currentUser.email,        
        instagram: "",
        facebook: "",
        youtube: "",
        platform: "Instagram",
        topics: [],
        referrals: [],
        referralCode,
        bio: "",
        city: "",
        languages: "",
        mobile: "",
        pageName: "",
        acceptBarter: false,
        createdAt: Date.now()        
    }
}