<template>
  <v-container fluid class="fill-height py-0">
    <v-row class="fill-height">      
      <v-col class="text-center" cols="12" md="6" align-self="center" >
        <v-img
          style="margin:0 auto;"
          contain
          width="80vw"
          height="30vh"
          src="@/assets/happy.png"
          max-height="60vh"
        ></v-img>
        <h1 class="headline font-weight-light mt-3">
          <v-icon color="success" left small>mdi-circle</v-icon> Account Active
        </h1>        
      </v-col>
      <v-col
      align-self="stretch"
        cols="12"
        md="6"
        class="secondary white--text text-center"
        :class="[$vuetify.breakpoint.name == 'xs'? 'align-start pt-4' : 'align-center']"
       
      >
        <div class="body-1 pa-2">
          We'll connect via email whenever a new promotion opportunity for you is out ✨
        </div>
        <div class="d-flex align-center justify-center mx-2 px-3 secondary lighten-2 py-5" style="margin-top:1.6rem;">
          <div class="body-2">
            <v-icon large dark>mdi-account-heart</v-icon>
          </div>
          <v-divider class="secondary mx-5" vertical></v-divider>
          <div class="text-left">
            <p class="body-2"> <strong>Tip:</strong> Refer friends and increase your chances of getting promos.</p>
            <div class="d-flex justify-space-between align-end">
              <v-btn @click="$emit('refer', currentPerson.referralCode)" small>REFER NOW  </v-btn>  
              <span v-if="currentPerson.referrals.length" class="body-2 font-italic">{{ currentPerson.referrals.length }} referred</span> 
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['currentPerson'],
  computed: {
    ...mapGetters(['currentUser'])
  }
};
</script>

<style scoped>
.bg {
  /* background: linear-gradient(to right, #fc466b, #3f5efb); */
  background:  #2b2b2b;
}
</style>