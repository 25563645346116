<template>
  <v-container>
    <v-row class="mt-4">
      <!-- <v-col cols="12"> -->
      <!-- <h1 class="overline">ENTER INFORMATION FOR THE POST</h1> -->
      <!-- </v-col> -->
      <v-col cols="12" lg="6">
        <v-select
          hide-details
          outlined
          :value="'Share/Repost'"
          label="Post Type"
          :items="['Share/Repost', 'Creative', 'Ad Banner']"
        ></v-select>
      </v-col>
      <v-col cols="12" lg="6">
        <h3 class="subtitle-2">Platforms</h3>
        <div class="d-flex">
          <v-checkbox class="mr-4" label="Facebook" ></v-checkbox>
          <v-checkbox label="Instagram"></v-checkbox>
        </div>
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
          placeholder="e.g. - https://www.facebook.com/postId"
          hide-details
          label="Post Link"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6">
        <!-- <div class="overline">PERIOD</div> -->
        <v-text-field
          hide-details
          outlined
          placeholder="Choose Date"
          label="Date Posted"
          :value="dateText"
          readonly
          @click="showSelectDateDialog = true"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" >
        <h3 class="subtitle-2 mb-3">Insights Screenshots</h3>
        <v-card width="100%">
          <v-row no-gutters>  
            <v-col md="8">
              <v-card-text class="pt-2">
                <v-row>
                  <v-col v-for="(image,index) in previewImages" :key="`img-${index}`" cols="4">
                    <v-card flat color="grey lighten-3">
                      <v-img aspect-ratio="0.56" :src="image">
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
                <form action="">
                  <div class="mt-4 d-flex justify-center flex-column">
                    <v-file-input
                      truncate-length="37"
                      outlined
                      hide-details
                      label="Select Files"
                      :loading="uploading"
                      multiple
                      @change="onFileSelected($event)"
                    ></v-file-input>
                  </div>
                </form>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" block depressed>SUBMIT</v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="showSelectDateDialog" max-width="400">
      <v-date-picker
        @input="onDateSelect($event)"
        v-model="date"
      ></v-date-picker>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["currentPerson"],
  data: () => ({
    date: "2022-11-07",
    dateText: null,
    showSelectDateDialog: false,
    uploading: false,
    previewImages: []
  }),
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    onDateSelect(val) {
      this.dateText = val.split("-").reverse().join("-");
      this.showSelectDateDialog = false;
    },
    onFileSelected(images) {
      if (images == null) {
        return;
      }
      this.previewImages = []
      for(let img of images) {
        this.previewImages.push(URL.createObjectURL(img))
      }

      let spaces = 3 - images.length
      this.previewImages = [...this.previewImages, ...Array(spaces).fill(null)]
    }
  },
};
</script>

<style scoped>
.bg {
  /* background: linear-gradient(to right, #fc466b, #3f5efb); */
  background: #2b2b2b;
}
</style>