import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbar: null,
    loading: false,
    currentUser: {}
  },
  getters: {
    snackbar(state) {
      return state.snackbar
    },
    loading(state) {
      return state.loading
    },
    currentUser(state) {
      return state.currentUser
    }
  },
  mutations: {
    SET_SNACKBAR(state, data) {
      state.snackbar = data
    },
    SET_LOADING_STATE(state, data) {
      state.loading = data
    },    
    SET_CURRENT_USER(state, data) {
      state.currentUser = data
    }
  },
  actions: {
  },
  modules: {
  }
})
