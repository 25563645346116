<template>
  <v-container fluid class="fill-height py-0">
    <v-row class="fill-height">      
      <v-col class="text-center" cols="12" md="6" align-self="center" >
        <v-img
          style="margin:0 auto;"
          contain
          width="80vw"
          src="@/assets/coo.png"
          max-height="60vh"
        ></v-img>
        <h1 class="display-1 font-weight-light mt-3">
          STARTING SOON
        </h1>        
      </v-col>
      <v-col
      align-self="stretch"
        cols="12"
        md="6"
        class="d-flex justify-center bg white--text text-center"
        :class="[$vuetify.breakpoint.name == 'xs'? 'align-start pt-4' : 'align-center']"
       
      >
        <div class="body-1 pa-2">
         DAILY FEED OF INFLUENCING TASKS THAT YOU CAN CHOOSE TO DO TO EARN MONEY OR DISCOUNTS
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.bg {
  /* background: linear-gradient(to right, #fc466b, #3f5efb); */
  background:  #2b2b2b;
}
</style>