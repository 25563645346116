<template>
  <stepper-content-card
    @skipStep="$emit('skipStep')"
    @changeStep="handleStepChange"
    :dataLoading="dataLoading"
    :step="3"
    imgUrl="https://images.unsplash.com/photo-1589652717406-1c69efaf1ff8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
  >
    <div slot="heading">Speciality</div>
    <div slot="subheading">Types of content you mostly create</div>
    <div slot="body">
      <v-row no-gutters>
        <v-col cols="12">
          <template v-if="!!selectedTopicList.length">
            <v-col cols="12" class="pt-0">
              <v-chip
                class="mr-2 mt-2"
                color="accent"
                close
                @click:close="selectedTopicList.splice(index, 1)"
                v-for="(topic, index) in selectedTopicList"
                :key="`topic-${index}.`"
              >
                {{ topic }}
              </v-chip>
            </v-col>
          </template>
        </v-col>
        <v-col cols="12">
          <v-select
            ref="topicSelectDropdown"
            placeholder="Tap here to select"
            outlined
            @change="addNewTopic"
            label="Add Industry"
            :items="filteredTopicList"
            hint="Max 5 selections"
            persistent-hint
            :disabled="selectedTopicList.length >= 5"
          />
        </v-col>
      </v-row>
    </div>
  </stepper-content-card>
</template>

<script>
import StepperContentCard from "./StepperContentCard";
import { mapGetters } from "vuex";

export default {
  props: ["currentPerson", "dataLoading"],
  data() {
    return {
      selectedTopicList: [],
      topicList: [
        "Beauty",
        "Fashion",
        "Food",
        "Lifestyle",
        "Comedy",
        "Technology",
        "Art",
        "Drama",
        "Writing",
        "News",
        "Photography",
        "Sports",
        "Health & Wellness",
        "Travel",
        "Education",
        "Music",
        "Dance",
        "Fitness",
        "Fun & Memes",
        "Content Creation",
        "Others",
      ],
    };
  },
  mounted() {
    if (this.currentPerson.id) {
      this.selectedTopicList = [...this.currentPerson.topics];
    }
  },
  computed: {
    filteredTopicList() {
      return this.topicList.filter(
        (topic) => !this.selectedTopicList.includes(topic)
      );
    },
    ...mapGetters(["currentUser"]),
  },
  methods: {
    handleStepChange() {
      if (!this.selectedTopicList.length) {
        this.$setSnackbar("Please select at least one option");
        return;
      }

      this.$emit("changeStep", {
        topics: this.selectedTopicList,
      });
    },
    addNewTopic(data) {
      this.selectedTopicList.push(data);
      this.$refs.topicSelectDropdown.reset();
      this.$refs.topicSelectDropdown.isFocused = false;
    },
  },
  components: {
    StepperContentCard,
  },
};
</script>