import './firebase'
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signOut, getAdditionalUserInfo  } from 'firebase/auth'

export const auth = getAuth()
export const currentUser = auth.currentUser
// export const authData = auth().currentUser

// const provider = new auth.GoogleAuthProvider()

const provider = new GoogleAuthProvider();

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, credentials.email, credentials.password)
    .then(result => {
      const user = result.user;
      // const isFirstLogin = getAdditionalUserInfo(result)
      resolve(user)      
  }).catch(error => {
      reject(error)
  });
  })  
}

export function signInWithGoogle() {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, provider)
    .then(result => {
      const user = result.user;
      const isFirstLogin = getAdditionalUserInfo(result).isNewUser
      resolve({ user, isFirstLogin })      
  }).catch(error => {
      reject(error)
  });
  })
}

export function appSignOut() {  
  return signOut(auth)
}

// export const authActions = { signIn, signInWithGoogle, signOut }
export const authActions = { signIn, appSignOut }