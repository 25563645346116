<template>
    <v-card flat class="text-center">
        <v-img height="250" :src="imgUrl"></v-img>
        <v-card-title primary-title class="justify-center mt-3">
            <div>
                <h5 class="headline mb-2"><slot name="heading"></slot></h5>
                <div class="grey--text text--darken-2 body-2"><slot name="subheading"></slot></div>
            </div>
        </v-card-title>          
        <v-card-text class="pb-4">
            <slot name="body"></slot>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-2">
            <!-- <v-btn v-if="step !== 1" color="grey lighten-1" text small @click="$emit('skipStep')" >SKIP FOR NOW</v-btn>       -->             
            <span class="ml-2 body-2 font-weight-bold">
            Step {{ step }} / 5  
            </span> 
            <v-spacer></v-spacer>
            <v-btn 
                class="ma-2"
                small
                v-if="step > 1"
                text  
                @click="goToPrev">PREV</v-btn>
            <v-btn 
                class="ma-2"
                small
                color="primary"
                text  
                :loading="dataLoading"
                @click="onNextBtnClick" >{{  nextBtnTxt }}</v-btn>
        </v-card-actions>
        <slot></slot>
    </v-card>
</template>

<script>
    export default {
        props: ["imgUrl", "lastStep", "step", "dataLoading"],
        data: () =>({
            btnLoading: false
        }),
        computed: {
            nextBtnTxt() {
                return this.lastStep? "FINISH" : "NEXT"
            }
        },
        methods: {
            goToPrev() {
                this.$router.replace({ path: "getting-started", query: { step: this.step-1 } });
            },
            onNextBtnClick() {
                this.btnLoading = true
                this.$emit('changeStep')
            }
        }
    }
</script>