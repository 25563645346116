<template>
  <v-container>
    <v-row>
      <!-- <v-col cols="12">
        <h1 class="overline">MATCHED PROMOS</h1>
      </v-col> -->
      <v-col cols="12" lg="6">
        <v-card to="/promo/1">
          <v-card-title class="d-flex justify-space-between">
            <h2 class="subtitle-1 font-weight-medium">
              The Assamese Project
            </h2>
            <v-chip small color="success">ACTIVE</v-chip>
          </v-card-title>
          <v-card-text>
            A movie which focuses on things which are very relatable to Assamese people...
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['currentPerson'],
  computed: {
    ...mapGetters(['currentUser'])
  }
};
</script>

<style scoped>
.bg {
  /* background: linear-gradient(to right, #fc466b, #3f5efb); */
  background:  #2b2b2b;
}
</style>