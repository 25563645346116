import { doc, collection, setDoc } from 'firebase/firestore'
import { db } from './../services/database'

const reviewsRef = collection(db, "Reviews")
const suggestionsRef = collection(db, "Suggestions")

export function addReview(data) {
    let docId = doc(reviewsRef).id
    return setDoc(doc(reviewsRef, docId), { ...data, createdAt: Date.now(), id: docId })
}

export function addSuggestion(data) {
    let docId = doc(suggestionsRef).id
    return setDoc(doc(suggestionsRef, docId), { ...data, createdAt: Date.now(), id: docId })
}

// function reviewsRef(id) {
//     return doc(db, "Influencers", id)
// }
