<template>
  <stepper-content-card
    @skipStep="$emit('skipStep')"
    @changeStep="handleStepChange"
    :dataLoading="dataLoading"
    :step="4"
    imgUrl="https://images.unsplash.com/photo-1522096823084-2d1aa8411c13?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
  >
    <div slot="heading">Contact</div>
    <div slot="subheading">Help clients connect with you</div>
    <div slot="body">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            class="mt-4"
            outlined
            dense
            v-model="name"
            label="Full Name"
            placeholder="Enter name here"
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            class="mt-4"
            outlined
            dense
            v-model="workEmail"
            label="Email"
            placeholder="Enter email here"
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            class="mt-4"
            outlined
            dense
            v-model="pageName"
            label="Page Name (if available)"
            placeholder="My page name"
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            class="mt-4"
            outlined
            dense
            v-model="mobile"
            type="tel"
            label="Phone (Optional)"
            hint="Phone number is never made public"
            persistent-hint
          />
        </v-col>
      </v-row>
    </div>
  </stepper-content-card>
</template>

<script>
import StepperContentCard from "./StepperContentCard";
import { mapGetters } from 'vuex'

export default {
  props: ["currentPerson", "dataLoading"],
  data() {
    return {
      name: "",
      workEmail: "",
      mobile: "",
      pageName: ""
    };
  },
  mounted() {
    this.name = this.currentPerson.name
    this.pageName = !!this.currentPerson.pageName? this.currentPerson.pageName : ""
    this.workEmail = this.currentPerson.workEmail
    this.mobile = this.currentPerson.mobile
  }, 
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    handleStepChange() {

      if(!this.workEmail.trim().length || !this.name.trim().length) {
        this.$setSnackbar("Name & Email are required")
        return
      }

      this.$emit("changeStep", {
        name: this.name,
        workEmail: this.workEmail,
        mobile: this.mobile,
        pageName: this.pageName
      });
    },
  },
  components: {
    StepperContentCard,
  },
};
</script>