<template>
  <div>
    <stepper-content-card
      @skipStep="$emit('skipStep')"
      @changeStep="handleStepChange()"
      :dataLoading="dataLoading"
      :step="1"
      imgUrl="https://images.unsplash.com/photo-1483691278019-cb7253bee49f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
    >
      <div slot="heading">Account Type</div>
      <div slot="subheading">
        Your app experience will be based on this
      </div>
      <div slot="body">
        <v-radio-group class="justify-center" :row="true" v-model="radios">
          <v-radio label="Individual" value="individual"></v-radio>
          <v-radio label="Page" value="page"></v-radio>
        </v-radio-group>
        <!-- <v-text-field
          class="mt-4"
          outlined
          v-if="radios == 'radio-0'"
          prepend-inner-icon="mdi-youtube"
          placeholder="https://youtube.com/your-channel-id"
          v-model="channelUrl"
          label="Paste your profile Link"
        /> -->
      </div>
    </stepper-content-card>
  </div>
</template>

<script>
import StepperContentCard from "./StepperContentCard";

export default {
  props: ["currentPerson", "dataLoading"],
  data() {
    return {
      radios: "individual",
    };
  },
  methods: {
    handleStepChange() {
      this.$emit("changeStep", { type: this.radios });
    },
  },
  components: {
    StepperContentCard,
  },
};
</script>