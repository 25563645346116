<template>
  <stepper-content-card
    @skipStep="$emit('skipStep')"
    @changeStep="handleStepChange"
    :dataLoading="dataLoading"
    :step="2"
    imgUrl="https://images.unsplash.com/photo-1643503640904-75c1a2093570?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
  >
    <div slot="heading">Social Presence</div>
    <div slot="subheading">Platforms where you publish your content</div>
    <div slot="body">
      <v-row no-gutters>
        <v-col cols="12">
          <v-select
            class="mt-4"
            outlined
            dense
            label="Primary Platform"
            hide-details
            v-model="platform"
            placeholder=""
            :items="[
              'Instagram',
              'Youtube',
              'Facebook'
            ]"
          >
          </v-select>            
        </v-col>
        <v-col class="text-center mt-4" cols="12">
            <h5 class="overline">
                PROFILE LINKS
            </h5>
        </v-col>
        <v-col cols="12">
          <v-text-field
            class="mt-4"
            outlined
            dense
            v-model="instagram"
            :label="`Instagram Profile Link ${platform=='Instagram'?'(required)':'(optional)'}`"
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            class="mt-4"
            outlined
            dense
            v-model="youtube"
            :label="`Youtube Profile Link ${platform=='Youtube'?'(required)':'(optional)'}`"
            hide-details
          />
        </v-col>        
        <v-col cols="12">
          <v-text-field
            class="mt-4"
            outlined
            dense
            v-model="facebook"
            :label="`Facebook Profile Link ${platform=='Facebook'?'(required)':'(optional)'}`"
            hide-details
          />
        </v-col>
      </v-row>
    </div>
  </stepper-content-card>
</template>

<script>
import StepperContentCard from "./StepperContentCard";
import { mapGetters } from 'vuex'

export default {
  props: ["currentPerson", "dataLoading"],
  data() {
    return {
      facebook: "",
      instagram: "",
      youtube: "",
      platform: ""
    };
  },
  mounted() {
    this.instagram = this.currentPerson.instagram
    this.facebook = this.currentPerson.facebook
    this.youtube = this.currentPerson.youtube
    this.platform = !!this.currentPerson.platform? this.currentPerson.platform : "Instagram"
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    handleStepChange() {
      let platformField = this.platform.toLowerCase()
      if(!this[platformField].trim()) {
        this.$setSnackbar(this.platform+ " profile link is required")
        return
      }

      this.$emit("changeStep", {
        instagram: this.instagram,
        facebook: this.facebook,
        youtube: this.youtube,
        platform: this.platform
      });

    },
  },
  components: {
    StepperContentCard,
  },
};
</script>