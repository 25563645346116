import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyBt3JFqyzIesA_lv1wkyN5drUew3hUXGgA",
  authDomain: "nefluen.firebaseapp.com",
  databaseURL: "https://nefluen.firebaseio.com",
  projectId: "nefluen",
  storageBucket: "nefluen.appspot.com",
  messagingSenderId: "138597729935",
  appId: "1:138597729935:web:797cea07a3179440a7d2fa",
  measurementId: "G-V8Z2HV335W"
};

export const app = initializeApp(firebaseConfig)


//!firebase.apps.length? firebase.initializeApp(firebaseConfig) : ''

// export const auth = firebase.auth()
// export default firebase